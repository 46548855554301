import { FC, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { toast } from 'react-toastify'

import { ImageProps } from 'next/image'
import type { Product } from '@commerce/types/product'
import { useAddItem, useCart } from '@framework/cart'
import WishlistButton from '@components/wishlist/WishlistButton'
import usePrice from '@framework/product/use-price'
import useCompareAtPrice from '@commerce/product/use-compare-at-price'
import { Button, useUI } from '@components/ui'
import { MODAL_VIEWS, PLACEHOLDER_IMG } from '@constants/common'
import {
  getProductVariant,
  selectDefaultOptionFromProduct,
  SelectedOptions,
} from '@components/product/helpers'

import useRemoveItem from '@framework/wishlist/use-remove-item'
import useWishlist from '@framework/wishlist/use-wishlist'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { useBrandThemeContext } from '@contexts/brandThemeContext'
import s from './ProductCard.module.css'

interface Props {
  className?: string
  product: Product
  imgProps?: { width: number | string; height: number | string } & Omit<
    ImageProps,
    | 'src'
    | 'layout'
    | 'placeholder'
    | 'blurDataURL'
    | 'width'
    | 'height'
    | 'alt'
  >
  isWishlistCard?: boolean
}

interface WishlistItem {
  created_at: string
  id: string
  product: Product
  product_id: number
  variant_id: number
}

const getCardButtonText = (loading: boolean, inStock?: boolean) => {
  if (loading) {
    return ''
  }

  return inStock === false ? 'Not Available' : 'Add to Cart'
}

const ProductCard: FC<Props> = ({
  className,
  product,
  imgProps,
  isWishlistCard,
}) => {
  const addItem = useAddItem()
  const [loading, setLoading] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({})
  const router = useRouter()
  const searchParams = useSearchParams()
  const q = searchParams?.get('q')
  const removeItem = useRemoveItem()
  const { data } = useWishlist()
  const {
    data: {
      attributes: {
        show_compare_at_price: showCompareAtPrice,
        decimal_mark: decimalMark,
        currency_format: currencyFormat,
        thousands_separator: thousandsSeparator,
        redirect_on_add_to_cart: redirectOnAddToCart,
        is_home_brand: isHomeBrand,
      },
    },
  } = useStoreDataContext()
  const { product_card_view_button_type: productCardViewButtonType } =
    useBrandThemeContext()
  const { data: cart } = useCart()
  const { setModalView, openModal } = useUI()
  const cartItem = useMemo(
    () => cart?.lineItems.find(lineItem => lineItem.productId === product.id),
    [cart?.lineItems, product.id],
  )
  const itemInWishlist = data?.items?.find(
    (item: WishlistItem) =>
      item.product_id === +product.id &&
      item.variant_id === +product.variants[0].id,
  )

  useEffect(() => {
    selectDefaultOptionFromProduct(product, setSelectedOptions)
  }, [product])

  const variant = getProductVariant(product, selectedOptions)
  const addToCart = async () => {
    if (loading) {
      return
    }
    if (cartItem && cartItem.quantity === 10) {
      setModalView(MODAL_VIEWS.bulkView, {
        maxWidth: 'extraSmall',
        productSku: product.sku,
      })
      openModal()

      return
    }
    setLoading(true)
    try {
      await addItem({
        productId: String(product.id),
        variantId: String(variant ? variant.id : product.variants[0]?.id),
      })
      window.dataLayer = window.dataLayer || []
      if (
        window.dataLayer.some(obj => obj.event === 'add_to_cart') &&
        window.dataLayer.some(
          obj => obj?.ecommerce?.items[0]?.item_id === product?.sku,
        )
      ) {
        window.dataLayer[
          window.dataLayer.findIndex(
            obj =>
              obj.event === 'add_to_cart' &&
              obj?.ecommerce?.items[0]?.item_id === product?.sku,
          )
        ] = {
          event: 'add_to_cart',
          ecommerce: {
            items: [
              {
                item_name: product.name,
                item_id: product.sku,
                price: product.price.value
                  .toFixed(2)
                  .replace('.', decimalMark ?? '.')
                  .replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    thousandsSeparator ||
                      (thousandsSeparator === null ? '' : ','),
                  ),
                item_brand: product.productProperties.brand?.value,
                item_variant: product.variants[0],
                quantity: 1,
                ...{
                  ...product.taxon?.reduce((acc, curr, index) => {
                    acc[`item_category${index + 1}`] = curr.attributes.name

                    return acc
                  }, {} as Record<string, string>),
                },
                item_list_name: q
                  ? 'Search results'
                  : product.taxon?.find(el => el.attributes.depth === 1)
                      ?.attributes.name,
                item_list_id: q
                  ? 'search_results'
                  : product.taxon?.find(el => el.attributes.depth === 1)?.id,
              },
            ],
          },
        }
      } else {
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            items: [
              {
                item_name: product.name,
                item_id: product.sku,
                price: product.price.value
                  .toFixed(2)
                  .replace('.', decimalMark ?? '.')
                  .replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    thousandsSeparator ||
                      (thousandsSeparator === null ? '' : ','),
                  ),
                item_brand: product.productProperties.brand?.value,
                item_variant: product.variants[0],
                quantity: 1,
                ...{
                  ...product.taxon?.reduce((acc, curr, index) => {
                    acc[`item_category${index + 1}`] = curr.attributes.name

                    return acc
                  }, {} as Record<string, string>),
                },
                item_list_name: product.taxon?.find(
                  el => el.attributes.depth === 1,
                )?.attributes.name,
                item_list_id: product.taxon?.find(
                  el => el.attributes.depth === 1,
                )?.id,
              },
            ],
          },
        })
      }
      if (itemInWishlist) await removeItem({ id: itemInWishlist.id })
      if (redirectOnAddToCart) {
        await router.push('/cart')
      }
      if (!redirectOnAddToCart) {
        toast.success(`${product.name} successfully added to the cart!`)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const { price } = usePrice({
    amount: product.price.value,
    baseAmount: product.price.retailPrice,
    currencyCode: product.price.currencyCode || '',
    format: currencyFormat,
  })
  const { price: compareAtPrice } = useCompareAtPrice({
    amount: product.price.value,
    compareAtPrice: product.price.compareAtPrice || 0,
    baseAmount: product.price.retailPrice,
    currencyCode: product.price.currencyCode || '',
    format: currencyFormat,
  })
  const rootClassName = cn(s.root, className)
  const isPrimaryOutlinedButton =
    productCardViewButtonType === 'outlined-primary'

  const imageAltTag = product.productProperties?.brand?.value
    ? `${product.productProperties?.brand?.value} ${product.name}`
    : product.name

  return (
    <>
      <div
        // eslint-disable-next-line react/no-unknown-property
        data-testid='product_card_root'
        className={rootClassName}
      >
        <Link
          data-testid='product_card_content'
          className={s.content}
          aria-label={product.name}
          onClick={ev => ev.stopPropagation()}
          href={`/products/${product.slug}`}
        >
          {product?.images && (
            <div
              // eslint-disable-next-line react/no-unknown-property
              data-testid='product_card_image_container'
              className={cn(s.imageContainer, s.productImageWrapper, {
                'items-center': !isHomeBrand,
                '!h-auto': isHomeBrand,
              })}
            >
              <div
                className={cn(s.centralButton, {
                  'border-base-border': !isPrimaryOutlinedButton,
                  'border-brand': isPrimaryOutlinedButton,
                  '!border-brand-hover !text-brand-hover font-bold':
                    isHomeBrand,
                })}
              >
                View Product
              </div>
              <img
                alt={imageAltTag}
                className={cn(s.productImage, {
                  'object-contain': !isHomeBrand,
                  '!h-auto': isHomeBrand,
                })}
                src={product.images[0]?.url || PLACEHOLDER_IMG}
                {...imgProps}
              />
            </div>
          )}
          {!isHomeBrand && (
            <h2 className={cn(s.name, { '!text-brand-hover': isHomeBrand })}>
              {product?.productProperties?.brand?.value}
            </h2>
          )}
          <span className={cn(s.description, { 'mt-4': isHomeBrand })}>
            {product.name}
          </span>
          <div className={s.priceWrapper}>
            <div className={cn(s.price, { '!text-brand-hover': isHomeBrand })}>
              {price}
            </div>
            {showCompareAtPrice && !!compareAtPrice && (
              // eslint-disable-next-line react/no-unknown-property
              <div
                data-testid='product_card_compare_price'
                className={s.oldPrice}
              >
                {compareAtPrice}
              </div>
            )}
          </div>
        </Link>
        {process.env.COMMERCE_CART_ENABLED && (
          <Button
            aria-label='Add to Cart'
            type='button'
            className={cn(s.addToCart, {
              '!font-bold sm:!font-semibold': isHomeBrand,
            })}
            onClick={addToCart}
            loading={loading}
            disabled={
              loading ||
              product?.isInStock === false ||
              (cartItem && cartItem.quantity === cartItem.totalOnHand)
            }
            variant='brand'
          >
            {getCardButtonText(loading, product?.isInStock)}
          </Button>
        )}
        {process.env.COMMERCE_WISHLIST_ENABLED && (
          <WishlistButton
            className={s.wishlistButton}
            productId={product.id}
            variant={product.variants[0]}
            isConfirmationRequired={isWishlistCard}
          />
        )}
      </div>
    </>
  )
}

export default ProductCard
